export default {
  abs(x: bigint) {
    return x < 0n ? -x : x
  },
  sign(x: bigint) {
    if (x === 0n) return 0n
    return x < 0n ? -1n : 1n
  },
  pow(base: bigint, exponent: bigint) {
    return base ** exponent
  },
  min(value: bigint, ...values: bigint[]) {
    let result = value

    for (const v of values) if (v < value) result = v
    return result
  },
  max(value: bigint, ...values: bigint[]) {
    let result = value
    for (const v of values) if (v > value) result = v
    return result
  }
}
