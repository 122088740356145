import { useMemo } from 'react'
import superTokenList, {
  SuperTokenInfo,
  TokenInfo,
  extendedSuperTokenList
} from '@superfluid-finance/tokenlist'
import { useChainId } from 'wagmi'
import { ChainId, isTestnet, supportedChainsById } from '@/config/networks'

export const useTokenList = () => {
  const userChainId = useChainId() as ChainId

  const all = useMemo(
    () =>
      (isTestnet(supportedChainsById[userChainId])
        ? extendedSuperTokenList
        : superTokenList
      ).tokens.filter(({ chainId }) => chainId === userChainId),
    [userChainId]
  )

  const [superTokens, underlyingTokens] = useMemo(
    () =>
      all.reduce(
        (acc, token) => {
          if (token.tags?.includes('supertoken')) {
            acc[0].push(token)
          } else {
            acc[1].push(token)
          }

          return acc
        },
        [[] /* SUPER */, [] /* UNDERLYING */] as [
          SuperTokenInfo[],
          TokenInfo[]
        ]
      ),
    [all]
  )

  return {
    all,
    superTokens,
    underlyingTokens
  }
}
