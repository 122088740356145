import { SiteConfig } from '@/types'

export const siteConfig: SiteConfig = {
  name: 'SuperBoring',
  description:
    'Buy the bottom of the market without being smart enough to time it. The most powerful, truly onchain DCA.',
  url: 'https://superboring.xyz',
  ogImage: 'https://tx.shadcn.com/og.jpg',
  links: {
    twitter: 'https://twitter.com/superboring_xyz ',
    github: 'https://github.com/superboringxyz'
  }
}
