import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import {
  Balances,
  FlowRate,
  Position,
  TimePeriod,
  Torex,
  TorexConfig,
  TotalReceivedByPool
} from '../types'
import { Address, formatEther, formatUnits, parseEther } from 'viem'
import { SuperTokenInfo } from '@superfluid-finance/tokenlist'
import { PortfolioQuery } from '@/subgraph/.graphclient'
import { FetchTokenPriceByContractResponse } from './fetch'
import { BenchmarkQuoteByTorex } from '@/hooks/useBenchmarkQuote'
import { format } from 'date-fns'
import { defaultFlowRate } from '@/constants'
import bigMath from './bigMath'
import { useTokenRelations } from '@/hooks/useTokenRelations'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const stringify: typeof JSON.stringify = (value, replacer, space) =>
  JSON.stringify(
    value,
    (key, value_) => {
      const value = typeof value_ === 'bigint' ? value_.toString() : value_
      return typeof replacer === 'function' ? replacer(key, value) : value
    },
    space
  )

export default function shortenHex(address: string, length = 4) {
  return `${address.substring(0, 2 + length)}...${address.substring(
    address.length - length,
    address.length
  )}`
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const timePeriods = ['day', 'week', 'month', 'year'] as const

export const mapTimePeriodToSeconds = (timePeriod: TimePeriod): bigint => {
  switch (timePeriod) {
    case 'day':
      return 86400n // 60 * 60 * 24 seconds in a day
    case 'week':
      return 604800n // 60 * 60 * 24 * 7 seconds in a week
    case 'month':
      return 2628000n // 60 * 60 * 24 * 30 seconds in a month (approximation)
    case 'year':
      return 31536000n // 60 * 60 * 24 * 365 seconds in a year (approximation)
    default:
      throw new Error(`Invalid time period: ${timePeriod}`)
  }
}

export const removeLocalization = (numberString: string) => {
  // Replace commas, non-breaking spaces, and normal spaces
  let result = numberString.replace(/,/g, '').replace(/\s/g, '')

  // Ensure there's only one period for decimal point, if any
  const parts = result.split('.')
  if (parts.length > 2) {
    // If there are more than one period, join all parts except the last one, then add a period and the last part
    result = `${parts.slice(0, -1).join('')}'.'${parts.slice(-1)}}`
  }

  return result
}

export const getFlowRatePerSecond = (flowRate?: FlowRate) => {
  return flowRate
    ? parseEther(flowRate.amountEth) / mapTimePeriodToSeconds(flowRate.period)
    : 0n
}

export const getOriginalFlowRate = (
  flowRatePerSecond: bigint,
  period: TimePeriod
): FlowRate => {
  const amountEth = formatUnits(
    flowRatePerSecond * mapTimePeriodToSeconds(period),
    18
  )

  return {
    amountEth,
    period
  }
}

export const toFixedFromString = (value?: string, significantDigits = 0) => {
  if (!value || Number.isNaN(Number(value))) return '0'

  const [integerPart] = value.split('.')

  const formatter = new Intl.NumberFormat('en-US', {
    minimumSignificantDigits: 3,
    maximumSignificantDigits: 8,
    ...(significantDigits > 0
      ? {
          minimumSignificantDigits: Math.min(
            significantDigits + integerPart.length,
            18
          ),
          maximumSignificantDigits: Math.min(
            significantDigits + integerPart.length,
            18
          )
        }
      : {})
  })

  return formatter.format(Number(value))
}

export const calculateDateWhenBalanceCritical = ({
  availableBalance,
  accountFlowRate,
  timestamp
}: {
  availableBalance: bigint
  timestamp: bigint
  accountFlowRate: bigint
}): Date | null => {
  if (accountFlowRate >= 0) {
    return null
  }

  const timeToCritical = availableBalance / (accountFlowRate * -1n)
  const criticalTimestamp = (timestamp + timeToCritical) * 1000n
  return new Date(Number(criticalTimestamp))
}

export const calculateDepositAmount = ({
  flowRate,
  liquidationPeriod,
  minimumDeposit
}: {
  liquidationPeriod: bigint
  flowRate: bigint
  minimumDeposit: bigint
}): bigint => {
  const calculatedDeposit = flowRate * liquidationPeriod

  return calculatedDeposit > minimumDeposit ? calculatedDeposit : minimumDeposit
}

export const torexDataToTorex = (
  superTokens: SuperTokenInfo[],
  torexData: Record<
    Address,
    {
      inToken: Address
      outToken: Address
      outTokenPool: Address
      rewardTokenPool: Address
      feeDistributionPool: Address
      minimumInTokenFlowRate: bigint
      config: TorexConfig
    }
  >
): Torex => {
  const [
    address,
    {
      inToken,
      outToken,
      outTokenPool: distribuitionPool,
      feeDistributionPool,
      rewardTokenPool,
      minimumInTokenFlowRate,
      config
    }
  ] = Object.entries(torexData)[0]

  const inboundToken = superTokens.find(
    ({ address }) => address.toLowerCase() === inToken.toLowerCase()
  )
  const outboundToken = superTokens.find(
    ({ address }) => address.toLowerCase() === outToken.toLowerCase()
  )

  if (!(inboundToken && outboundToken)) {
    throw new Error('Torex tokens not found!') // should not happen
  }

  return {
    address: address as Address,
    inboundToken,
    outboundToken,
    distribuitionPool,
    rewardTokenPool,
    feeDistributionPool,
    minimumInTokenFlowRate,
    config
  }
}

export const stringifyTorex = (torex?: Torex) => {
  const { getUnderlyingTokenOf } = useTokenRelations()

  if (!torex) return ''

  const inboundUnderlyingToken = getUnderlyingTokenOf(
    torex.inboundToken.address
  )
  const outboundUnderlyingToken = getUnderlyingTokenOf(
    torex.outboundToken.address
  )

  if (!inboundUnderlyingToken || !outboundUnderlyingToken) return ''

  return `${inboundUnderlyingToken.symbol} / ${outboundUnderlyingToken.symbol}`
  // return torex ? `${torex.inboundToken.symbol} / ${torex.outboundToken.symbol}` : ''
}

type InvertRecord<T extends Record<string, string>> = {
  [K in T[keyof T]]: Extract<keyof T, string>
}

export const invertRecord = <T extends Record<string, string>>(
  obj: T
): InvertRecord<T> => {
  const invertedObject = {} as InvertRecord<T>

  for (const [key, value] of Object.entries(obj)) {
    invertedObject[value as T[keyof T]] = key as Extract<keyof T, string>
  }
  return invertedObject
}

export const formatFiatValue = (
  amount: number,
  unitPrice?: number,
  decimalPlaces = 2,
  ccySymbol = '$'
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })
  return unitPrice && unitPrice > 0 ? formatter.format(amount * unitPrice) : ''
}

export const calculateTotalValueStreamed = (
  timestamp: bigint,
  prices?: FetchTokenPriceByContractResponse,
  data?: PortfolioQuery
) => {
  if (prices && data) {
    const streams =
      data?.streams.filter(
        ({ createdAtTimestamp }) => BigInt(createdAtTimestamp) <= timestamp
      ) ?? []

    return streams.reduce<number>(
      (
        acc,
        {
          sender,
          receiver,
          streamedUntilUpdatedAt,
          currentFlowRate,
          updatedAtTimestamp,
          createdAtTimestamp,
          token
        }
      ) => {
        const totalBackAdjustment = data.transferEvents
          .filter(
            ({ from, to, token: transferEventToken }) =>
              from.id === sender.id &&
              to.id === receiver.id &&
              token.id === transferEventToken
          )
          .reduce((acc, { value }) => acc + BigInt(value), 0n)

        const totalStreamed = formatEther(
          BigInt(streamedUntilUpdatedAt) +
            totalBackAdjustment +
            BigInt(currentFlowRate) *
              (timestamp -
                (BigInt(updatedAtTimestamp) ?? BigInt(createdAtTimestamp)))
        )

        return prices[token.id as Address]
          ? acc + Number(totalStreamed) * prices[token.id as Address].usd
          : 0
      },
      0
    )
  }

  return 0
}

export const calculateTotalValueReceived = (
  portfolio: Position[],
  fiatPrices?: FetchTokenPriceByContractResponse
) =>
  fiatPrices
    ? portfolio.reduce<number>((acc, { totalReceived, torex }) => {
        const price =
          fiatPrices[torex.outboundToken.address as Address]?.usd ?? 0

        return acc + Number(formatEther(totalReceived)) * price
      }, 0)
    : 0

export const aggregatePortfolioData = (
  timestamp: bigint,
  torexes: Record<Address, Torex>,
  benchmarkQuoteByTorex: BenchmarkQuoteByTorex,
  data?: PortfolioQuery,
  totalAmountReceivedByPool?: TotalReceivedByPool
) => {
  const netBackAdjustmentsPerTorex = Object.entries(torexes).reduce<
    Record<string, bigint>
  >((acc, [_, torex]) => {
    const torexId = `${torex.inboundToken.address}:${torex.outboundToken.address}`

    const netBackAdjustments =
      data?.transferEvents.reduce<bigint>((acc, { value, to, from }) => {
        if (to.id === torex?.address) {
          return acc + BigInt(value)
        } else if (from.id === torex?.address) {
          return acc - BigInt(value)
        }

        return acc
      }, 0n) ?? 0n

    return Object.assign(acc, {
      [torexId]: netBackAdjustments
    })
  }, {})

  return Object.values(
    data?.streams
      .filter(
        ({ createdAtTimestamp }) => BigInt(createdAtTimestamp) <= timestamp
      )
      .reduce<Record<string, Position>>(
        (
          acc,
          {
            receiver,
            currentFlowRate,
            createdAtTimestamp,
            updatedAtTimestamp,
            streamedUntilUpdatedAt
          }
        ) => {
          const torex = torexes[receiver.id as Address]

          if (!torex) return acc

          const torexId = `${torex.inboundToken.address}:${torex.outboundToken.address}`

          const flowRate = BigInt(currentFlowRate)
          const totalStreamed =
            (acc[torexId]?.totalStreamed ?? 0n) +
            BigInt(streamedUntilUpdatedAt) +
            BigInt(currentFlowRate) *
              (timestamp - BigInt(updatedAtTimestamp ?? createdAtTimestamp))

          const totalReceived =
            totalAmountReceivedByPool?.[torex.distribuitionPool] ?? 0n

          return Object.assign(acc, {
            [torexId]: {
              torex,
              flowRate,
              totalStreamed,
              totalReceived,
              netBackAdjustments: netBackAdjustmentsPerTorex[torexId] ?? 0n,
              createdAtTimestamp: Number(createdAtTimestamp),
              updatedAtTimestamp: Number(updatedAtTimestamp),
              status: 'active'
            }
          })
        },
        {}
      ) ?? {}
  )
}

export const ANIMATION_MINIMUM_STEP_TIME = 75

const abs = (n: bigint) => (n < 0n ? -n : n)

export const getSignificantFlowingDecimal = (flowRate: bigint) => {
  if (flowRate === 0n) {
    return 0
  }

  const ticksPerSecond = 1000 / ANIMATION_MINIMUM_STEP_TIME
  const flowRatePerTick = flowRate / BigInt(~ticksPerSecond)

  const [beforeEtherDecimal, afterEtherDecimal] =
    formatEther(flowRatePerTick).split('.')

  const isFlowingInWholeNumbers = abs(BigInt(beforeEtherDecimal)) > 0n

  if (isFlowingInWholeNumbers) {
    return 0 // Flowing in whole numbers per tick.
  }
  const numberAfterDecimalWithoutLeadingZeroes = BigInt(afterEtherDecimal)

  const lengthToFirstSignificatDecimal = afterEtherDecimal
    .toString()
    .replace(numberAfterDecimalWithoutLeadingZeroes.toString(), '').length // We're basically counting the zeroes.

  return Math.min(lengthToFirstSignificatDecimal + 2, 18)
}

export const calculateStreamEnds = (balance: bigint, netFlow: bigint) => {
  if (netFlow === 0n) return 'Ended'
  if (netFlow > 0n) return 'Never'

  return format(
    Date.now() + Number(balance / -netFlow) * 1000,
    "do MMM'.' yyyy HH:MM"
  )
}

export const averageDiffTimestamps = (timestamps?: number[]) => {
  if (!timestamps || timestamps.length < 2) {
    return 15 * 60 // just return 15 minutes if there are not enough LMEs
  }

  const totalDiff = timestamps.slice(1).reduce((acc, curr, index) => {
    const prevDate = new Date(timestamps[index] * 1000)
    const currDate = new Date(curr * 1000)
    const diff = Math.abs(currDate.getTime() - prevDate.getTime())
    return acc + diff
  }, 0)

  const averageDiff = totalDiff / (timestamps.length - 1)
  return averageDiff
}

export const getStreamedAmountForPeriod = (
  flowRate: FlowRate = defaultFlowRate,
  period: TimePeriod = flowRate.period
) => {
  const fps = getFlowRatePerSecond(flowRate)

  switch (period ?? flowRate.period) {
    case 'day':
      return fps * mapTimePeriodToSeconds('day')
    case 'week':
      return fps * mapTimePeriodToSeconds('week')
    case 'month':
      return fps * mapTimePeriodToSeconds('month')
    case 'year':
      return fps * mapTimePeriodToSeconds('year')
    default:
      return fps
  }
}

export const adjustDecimals = (
  value = 0n,
  currentDecimals = 18,
  targetDecimals = 18
): bigint => {
  const decimalDifference = BigInt(targetDecimals) - BigInt(currentDecimals)

  if (decimalDifference === 0n) return value

  return decimalDifference > 0n
    ? value * 10n ** decimalDifference
    : value / 10n ** -decimalDifference
}

export const calculateTotalBalance = (
  balances: Balances | undefined,
  mode: 'underlying' | 'super'
) => {
  if (!balances) return 0n

  if (mode === 'underlying') {
    return (
      (balances.underlying?.balance ?? 0n) +
      adjustDecimals(
        balances.token?.balance,
        balances.token?.decimals,
        balances.underlying?.decimals
      )
    )
  }

  return (
    (balances.token?.balance ?? 0n) +
    adjustDecimals(
      balances.underlying?.balance,
      balances.underlying?.decimals,
      balances.token?.decimals
    )
  )
}

type CalculateWrapAmountArgs = {
  flowRate?: FlowRate
  superTokenBalance?: bigint
  underlyingBalance?: bigint
  underlyingDecimals?: number
  existingFlowRate?: bigint
}

export const calculateAmountToWrap = ({
  flowRate = defaultFlowRate,
  superTokenBalance = 0n,
  underlyingBalance = 0n,
  underlyingDecimals = 18,
  existingFlowRate = 0n
}: CalculateWrapAmountArgs) => {
  const underlyingBalanceAdjusted = adjustDecimals(
    underlyingBalance,
    underlyingDecimals,
    18
  )
  const streamedAmountForPeriod = getStreamedAmountForPeriod(flowRate)
  const adjustWithExistingFlowRate = bigMath.abs(existingFlowRate * 120n)

  const clampedAmountToWrap = bigMath.max(
    0n,
    bigMath.min(
      underlyingBalanceAdjusted,
      streamedAmountForPeriod -
        superTokenBalance +
        adjustWithExistingFlowRate +
        100n
    )
  )

  return adjustDecimals(clampedAmountToWrap, 18, underlyingDecimals)
}
